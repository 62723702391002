import { CourseDetailsCard, CourseDetailsCardI18N } from './course-details-card';
import { Course } from '@stellar-lms-frontend/lms-graphql';
import { Section } from '../section/section';
import { LinkButton, Edit3Icon } from '@stellar-lms-frontend/ui-components';

export type CourseDetailsSectionProps = {
  course?: Course;
  editable?: boolean;
  showEmptyState?: boolean;
  onEditClick?: () => void;
  i18n: CourseDetailsSectionI18N;
};

export type CourseDetailsSectionI18N = CourseDetailsCardI18N & {
  title: string;
  edit?: string;
};

export const CourseDetailsSection: React.FC<CourseDetailsSectionProps> = ({
  course,
  editable = false,
  showEmptyState = false,
  onEditClick = () => null,
  i18n,
}) => {
  if (!course) return null;

  return (
    <Section
      title={i18n.title}
      rightSideType="react-element"
      rightSideContent={
        editable && (
          <LinkButton
            onClick={onEditClick}
            leftIcon={<Edit3Icon className="text-text-01 h-6 w-6" />}
            label={i18n.edit ?? ''}
            buttonStyle="fit_small_padding"
          />
        )
      }
    >
      <CourseDetailsCard
        course={course}
        showEmptyState={showEmptyState}
        i18n={i18n}
      />
    </Section>
  );
};
