import { Course, graphql, QueryCourseArgs } from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';

export const getDesignerCourseDashboard = (client: GraphQLClient, variables: QueryCourseArgs) => {
  return client
    .request(
      graphql(`
        query DesignerCourseDashboard($id: ID!) {
          course(id: $id) {
            id
            title
            outline {
              modules {
                id
                title
                description
                stats {
                  learnersStarted
                  learnersCompleted
                }
              }
            }
            learners {
              user {
                id
                name
                thumbUri
              }
            }
            aiGenerated
            aiGenerationDone
            description
            skills
            objectives {
              id
              name
            }
            designers {
              id
              name
              thumbUri
            }
            mentors {
              id
              name
              thumbUri
            }
            realtimeChannel {
              id
            }
            transferObjectives {
              id
              name
              description
            }
            language
            courseStats {
              ... on CourseStats {
                averageCompletionRate
                numberOfActiveStudents
                numberOfStudents
                averageTimeSpentInSeconds
              }
            }
          }
        }
      `),
      variables
    )
    .then((res) => res.course as Course);
};
