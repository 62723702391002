import { useTranslation } from 'react-i18next';
import { RelatedStepItem } from './related-step-item';
import Skeleton from 'react-loading-skeleton';
import {
  RelatedStepSuggestionsQuery,
  SuggestedRelatedContentItem,
  SuggestionGenerationStatus,
} from '@stellar-lms-frontend/lms-graphql';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { Types as AblyTypes } from 'ably';
import { useAblyChannel } from '@stellar-lms-frontend/common-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons';

type Props = {
  courseId?: string;
  moduleId?: string;
  learningActivityId?: string;
  courseRealtimeChannelId?: string;
  fetchRelatedSteps: (
    courseId: string,
    moduleId: string,
    learningActivityId: string,
    nrPerPage: number
  ) => Promise<RelatedStepSuggestionsQuery>;
  startRelatedStepSuggestionGeneration: (
    courseId: string,
    moduleId: string,
    learningActivityId: string
  ) => Promise<boolean>;
  startRelatedContentStepGeneration: (
    courseId: string,
    moduleId: string,
    learningActivityId: string,
    suggestionId: string,
    suggestionTitle: string,
    suggestionDescription: string
  ) => Promise<boolean>;
};

const QUERY_KEY = 'RELATED_STEP_SUGGESTIONS';

export const RelatedStepsList = ({
  courseId,
  moduleId,
  learningActivityId,
  courseRealtimeChannelId,
  fetchRelatedSteps,
  startRelatedStepSuggestionGeneration,
  startRelatedContentStepGeneration,
}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.related-steps-list' });
  const queryClient = useQueryClient();

  const isComponentEnabled = !!courseId && !!moduleId && !!learningActivityId;
  const relatedStepsQuery = useQuery({
    queryKey: [QUERY_KEY, courseId, moduleId, learningActivityId],
    queryFn: () =>
      isComponentEnabled ? fetchRelatedSteps(courseId, moduleId, learningActivityId, 5) : null,
    enabled: isComponentEnabled,
    refetchOnWindowFocus: false, // only refetch on ably so that we can do optimistic updates
  });

  const generateStep = (suggestion: SuggestedRelatedContentItem) => {
    if (isComponentEnabled) {
      startRelatedContentStepGeneration(
        courseId,
        moduleId,
        learningActivityId,
        suggestion.id,
        suggestion.title,
        suggestion.description
      ).then(() => {
        queryClient.setQueryData(
          [QUERY_KEY, courseId, moduleId, learningActivityId],
          (oldData: RelatedStepSuggestionsQuery | undefined) =>
            oldData
              ? {
                  ...oldData,
                  suggestedRelatedContent: {
                    ...oldData?.suggestedRelatedContent,
                    suggestions: oldData?.suggestedRelatedContent?.suggestions?.filter(
                      (s) => s.id !== suggestion.id
                    ),
                  },
                }
              : undefined
        );
      });
    }
  };

  useAblyChannel(courseRealtimeChannelId, (message: AblyTypes.Message) => {
    // There are more granular events, but no channel for this
    if (message.data.type === 'Module') {
      queryClient.invalidateQueries([QUERY_KEY, courseId, moduleId, learningActivityId]);
    }
  });

  const startSuggestionGeneration = useCallback(
    (courseId: string, moduleId: string, learningActivityId: string) =>
      startRelatedStepSuggestionGeneration(courseId, moduleId, learningActivityId).then(() => {
        queryClient.setQueryData([QUERY_KEY, courseId, moduleId, learningActivityId], () => ({
          __typename: 'Query',
          suggestedRelatedContent: {
            status: SuggestionGenerationStatus.InProgress,
            suggestions: [],
          },
        }));
      }),
    [queryClient, startRelatedStepSuggestionGeneration]
  );

  useEffect(() => {
    // This might run multiple times, needs testing (on staging)
    if (isComponentEnabled && relatedStepsQuery.isFetched) {
      const suggestions = relatedStepsQuery.data?.suggestedRelatedContent?.suggestions ?? [];
      const generationStatus =
        relatedStepsQuery.data?.suggestedRelatedContent?.status ?? SuggestionGenerationStatus.None;

      if (suggestions.length === 0 && generationStatus === SuggestionGenerationStatus.None) {
        startSuggestionGeneration(courseId, moduleId, learningActivityId);
      }
    }
  }, [
    courseId,
    isComponentEnabled,
    learningActivityId,
    moduleId,
    queryClient,
    relatedStepsQuery.data,
    relatedStepsQuery.isFetched,
    startRelatedStepSuggestionGeneration,
    startSuggestionGeneration,
  ]);

  return isComponentEnabled ? (
    <div>
      <div className="flex">
        <p className="type-body text-text-02 font-lexend mb-3 grow">{t('title')}</p>
        <FontAwesomeIcon
          className="text-text-02 mx-2 shrink-0 grow-0 cursor-pointer text-xl"
          icon={faArrowsRotate}
          onClick={() => startSuggestionGeneration(courseId, moduleId, learningActivityId)}
        />
      </div>
      <ul className="space-y-3">
        {relatedStepsQuery.data?.suggestedRelatedContent.status === SuggestionGenerationStatus.Done
          ? relatedStepsQuery.data?.suggestedRelatedContent.suggestions?.slice(0, 3).map((step) => (
              <li key={step.id}>
                <RelatedStepItem
                  title={step.title}
                  description={step.description}
                  action={() => generateStep(step)}
                />
              </li>
            ))
          : [...Array(3)].map((_, index) => (
              <li key={index}>
                <Skeleton
                  enableAnimation={true}
                  width={200}
                />
              </li>
            ))}
      </ul>
    </div>
  ) : null;
};
