import { useCallback, useEffect, useMemo, useState } from 'react';
import { Heading3, LinkButton, PrimaryButton, Sidebar } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { VoidFunc, useAblyChannel } from '@stellar-lms-frontend/common-utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SuggestionGenerationStatus } from '@stellar-lms-frontend/lms-graphql';
import { BehaviourList, LocalEditableCheckboxCardState } from './behaviour-list';
import { Types } from 'ably';
import * as Sentry from '@sentry/react';
import { TransferObjectivesModal } from '../../transfer-objectives-modal/transfer-objectives-modal';

const queryKey = 'behavior-generation';

export type Behaviour = {
  id: string;
  title: string;
  description: string;
};

type GenerationState = 'initializing' | 'generating' | 'fetching suggestions' | 'finished';
const loadingStates = ['initializing', 'generating', 'fetching suggestions'];

type Props = {
  ablyChannelId?: string;
  isOpen: boolean;
  onClose: VoidFunc;
  actions: {
    startBehaviourGeneration: () => Promise<boolean>;
    fetchBehaviourSuggestions: () => Promise<{
      status: SuggestionGenerationStatus;
      suggestions: Behaviour[];
    }>;
    generateBehaviourContent: (input: Behaviour[]) => Promise<boolean>;
  };
};
export const BehaviourAISidebar = ({ isOpen, onClose, ablyChannelId, actions }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.behaviour.ai-sidebar' });
  const [generationState, setGenerationState] = useState<GenerationState>('initializing');
  const [isBehaviourModalOpen, setIsBehaviourModalOpen] = useState(false);

  const [currentSuggestions, setCurrentSuggestions] = useState<LocalEditableCheckboxCardState[]>(
    []
  );
  const queryClient = useQueryClient();

  useAblyChannel(ablyChannelId, (message: Types.Message) => {
    setGenerationState('fetching suggestions');
    queryClient.invalidateQueries([queryKey]);
  });

  const onGenerateBehaviourContent = useCallback(
    (selectedBehaviours: LocalEditableCheckboxCardState[]) => {
      actions.generateBehaviourContent(
        selectedBehaviours.map((b) => ({
          title: b.title ?? '',
          description: b.description ?? '',
          id: b.id,
        }))
      );
    },
    [actions]
  );

  useEffect(() => {
    if (isOpen && generationState === 'initializing') {
      setGenerationState('generating');
      setCurrentSuggestions([]);
      actions
        .startBehaviourGeneration()
        .then()
        .catch((err) => {
          Sentry.captureException(new Error('Behaviour generation was unable to start'), {
            extra: {
              error: err,
            },
          });
        });
    }
  }, [isOpen, actions, generationState]);

  const { data } = useQuery(
    [queryKey],
    async () => {
      const data = await actions.fetchBehaviourSuggestions();
      if (data.status === SuggestionGenerationStatus.Done) {
        setGenerationState('finished');
      }

      return data.suggestions;
    },
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: generationState === 'fetching suggestions',
    }
  );

  const selectableBehaviours = useMemo(() => {
    const ret = [...currentSuggestions];
    if (!data) {
      return ret;
    }

    data.forEach((s) => {
      if (currentSuggestions.every((a) => a.id !== s.id)) {
        ret.push({
          isSelected: false,
          ...s,
        });
      }
    });

    return ret;
  }, [currentSuggestions, data]);

  const onClickGenerateBehaviourContent = () => {
    const selectedItems = currentSuggestions.filter((i) => i.isSelected);
    selectedItems.length > 0 && onGenerateBehaviourContent(selectedItems);
    onClose();

    // reset the query data so that selectableBehaviours gets reset to no options as well.
    queryClient.setQueryData([queryKey], []);
    setGenerationState('initializing');
    setCurrentSuggestions([]);
  };

  return (
    <>
      <Sidebar
        isOpen={isOpen}
        onClose={onClose}
        position={'right'}
        contentClassName="md:max-w-[600px] md:w-[60%] font-lexend"
        isDisableScrollEnabled={false}
        hasBackdrop={false}
        header={
          <div className="space-y-6">
            <Heading3 className="text-text-01">{t('title')}</Heading3>
            <p className="font-lexend text-text-02 type-small-medium">
              {t('description')}
              &nbsp;
              <span
                tabIndex={0}
                role="button"
                className="underline"
                onKeyDown={() => setIsBehaviourModalOpen(true)}
                onClick={() => setIsBehaviourModalOpen(true)}
              >
                {t('behaviours-explained-link')}
              </span>
            </p>
          </div>
        }
        buttons={{
          hasDivider: true,
          buttons: [
            <PrimaryButton
              label={t('generate-button')}
              enabled={currentSuggestions.filter((i) => i.isSelected).length > 0}
              onClick={onClickGenerateBehaviourContent}
            />,
            <LinkButton
              label={t('cancel-button')}
              onClick={onClose}
            />,
          ],
        }}
      >
        <div className="space-y-4">
          {generationState !== 'initializing' && (
            <BehaviourList
              isLoading={loadingStates.includes(generationState)}
              behaviours={selectableBehaviours}
              onChange={(newItems) => setCurrentSuggestions(newItems)}
            ></BehaviourList>
          )}
        </div>
      </Sidebar>
      <TransferObjectivesModal
        isOpen={isBehaviourModalOpen}
        onClose={() => setIsBehaviourModalOpen(false)}
      />
    </>
  );
};
