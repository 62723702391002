import { ModuleList, USE_COURSE_OUTLINE } from '@stellar-lms-frontend/lms-components';
import {
  Course,
  CourseStats,
  LearningActivityCreateInput,
  MoveLearningActivityMutationVariables,
  LearningActivityType,
  useCurrentCompany,
  useCurrentUser,
} from '@stellar-lms-frontend/lms-graphql';
import {
  Comment2Info,
  LogoLoader,
  LinkButton,
  Modal,
  PrimaryButton,
} from '@stellar-lms-frontend/ui-components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import {
  createCourseModule,
  createLearningActivity,
  deleteCourseModule,
  duplicateCourseModule,
  fetchSuggestedBehaviours,
  getDesignerJourney,
  moveLearningActivity,
  startBehaviourContentGeneration,
  startBehaviourGeneration,
  updateCourseModule,
  updateCourseModuleOrder,
} from './designer-journey.api';
import { toast } from 'react-toastify';
import { defaultGraphqlClient, useAblyChannel } from '@stellar-lms-frontend/common-utils';
import { Types } from 'ably';
import { COURSE } from '../constants/query-constants';
import {
  useDeleteLearningActivity,
  useDuplicateLearningActivity,
  useUpdateLearningActivity,
} from '../../../api/learning-activity.api';
import { GEARTemplateModal } from '../gear-template-modal/gear-template-modal';
import { UIShell } from '../../shell/ui-shell';

type DeleteModalConfig = {
  modalTitle: string;
  modalDescription: string;
  ctaLabel: string;
  onDelete: () => void;
};

export const DesignerJourneyPage = () => {
  const { courseId = '' } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'module-overview' });
  const { t: tLaEdit } = useTranslation('translation', { keyPrefix: 'learning-activity-edit' });
  const { t: tLaActions } = useTranslation('translation', {
    keyPrefix: 'learning-activity-actions',
  });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });
  const [searchParams, setSearchParams] = useSearchParams();
  const [deleteModalConfig, setDeleteModalConfig] = useState<DeleteModalConfig>();
  const queryClient = useQueryClient();
  const location = useLocation();
  const [isEditing, setIsEditing] = useState(location?.state?.isEditing ?? false);
  const [forceModuleId, setForceModuleId] = useState<string | undefined>();
  const [isGEARModalOpen, setIsGEARModalOpen] = useState(false);
  const [nextModuleIdToSelect, setNextModuleIdToSelect] = useState<string | undefined>();
  const [currentCourseId, setCurrentCourseId] = useState('');

  const {
    query: { data: currentCompany },
  } = useCurrentCompany(defaultGraphqlClient);

  const {
    query: { data: currentUser },
  } = useCurrentUser(defaultGraphqlClient);

  useEffect(() => {
    if (location?.state?.isEditing || searchParams.get('state') === 'edit') {
      setIsEditing(true);
    }
  }, [location?.state?.isEditing, searchParams]);

  const { data: course } = useQuery<Course | undefined>(
    [COURSE, courseId],
    ({ signal }) => (courseId ? getDesignerJourney({ id: courseId }, signal) : undefined),
    {
      onSuccess: (courseData) => {
        if (courseData && courseId && currentCourseId !== courseId) {
          setCurrentCourseId(courseId);
          setSearchParams({ moduleId: '' });
        }
        if (nextModuleIdToSelect) {
          setForceModuleId(nextModuleIdToSelect);
          setNextModuleIdToSelect(undefined);
        }
      },
    }
  );

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries([COURSE, courseId]);
    queryClient.invalidateQueries([USE_COURSE_OUTLINE, courseId]);
  }, [courseId, queryClient]);

  useAblyChannel(course?.realtimeChannel?.id, (message: Types.Message) => {
    if (message.name === 'create' && message.data.type === 'Module') {
      invalidateQueries();
    }
  });

  const { mutate: createModuleMutation } = useMutation(createCourseModule, {
    onSuccess: (data) => {
      invalidateQueries();
      setNextModuleIdToSelect(data.createCourseModule?.id);
    },
  });

  const { mutate: updateModuleMutation } = useMutation(updateCourseModule, {
    onSuccess: () => {
      invalidateQueries();
    },
  });

  const { mutate: duplicateCourseModuleMutation } = useMutation(duplicateCourseModule, {
    onSuccess: (data) => {
      invalidateQueries();
      toast.success(t('module-edit.duplicate-success'));
    },
  });

  const { mutate: updateCourseModuleOrderMutation } = useMutation(updateCourseModuleOrder, {
    onSuccess: () => {
      invalidateQueries();
    },
  });
  const startBehaviourGenerationMutation = useMutation(startBehaviourGeneration);
  const startBehaviourContentGenerationMutation = useMutation(startBehaviourContentGeneration);

  const { mutate: deleteLearningActivityMutation } = useDeleteLearningActivity({
    onSuccess: () => toast.success(tLaActions('delete.success')),
  });
  const { mutate: updateLearningActivityMutation } = useUpdateLearningActivity({
    onSuccess: () => toast.success(tLaActions('update-success')),
  });
  const { mutate: duplicateLearningActivityMutation } = useDuplicateLearningActivity({
    onSuccess: () => toast.success(tLaActions('duplicate-success')),
  });

  const { mutate: deleteCourseModuleMutation } = useMutation(
    (variables: { courseId: string; moduleId: string }) => deleteCourseModule({ input: variables }),
    {
      onSuccess: () => {
        invalidateQueries();
        toast.success(t('module-edit.delete.success'));
      },
    }
  );

  const { mutate: createLearningActivityMutation } = useMutation(
    (variables: { courseId: string; moduleId: string; input: LearningActivityCreateInput }) =>
      createLearningActivity(variables),
    {
      onSuccess: () => {
        invalidateQueries();
        toast.success(tLaActions('create-success'));
      },
    }
  );

  const { mutate: moveLearningActivityMutation } = useMutation(
    (variables: MoveLearningActivityMutationVariables) => moveLearningActivity(variables),
    {
      onSuccess: () => {
        invalidateQueries();
      },
    }
  );

  const handleMoveLearningActivityMutation = (
    moduleId: string,
    learningActivityId: string,
    input: { targetPosition: number; targetModuleId: string }
  ) =>
    moveLearningActivityMutation({
      courseId,
      moduleId,
      learningActivityId,
      input,
    });

  const handleCreateLearningActivityAction = (
    moduleId: string,
    learningActivity: LearningActivityCreateInput
  ) => {
    createLearningActivityMutation({ courseId, moduleId, input: learningActivity });
  };

  const handleUpdateLearningActivityMutation = (
    moduleId: string,
    learningActivityId: string,
    learningActivity: LearningActivityCreateInput
  ) => {
    updateLearningActivityMutation({
      courseId,
      moduleId,
      learningActivityId,
      input: learningActivity,
    });
  };

  const handleDuplicateLearningActivityMutation = (
    moduleId: string,
    learningActivityId: string
  ) => {
    duplicateLearningActivityMutation({ courseId, moduleId, learningActivityId });
  };

  const handleDeleteAction = (
    { moduleId, learningActivityId }: { moduleId?: string; learningActivityId?: string },
    type: 'learning-activity' | 'module'
  ) => {
    switch (type) {
      case 'learning-activity':
        setDeleteModalConfig({
          modalTitle: tLaActions('delete.title'),
          modalDescription: tLaActions('delete.description'),
          ctaLabel: tLaActions('delete.cta-delete'),
          onDelete: () =>
            moduleId &&
            learningActivityId &&
            deleteLearningActivityMutation({ courseId, moduleId, learningActivityId }),
        });
        break;
      case 'module':
        setDeleteModalConfig({
          modalTitle: t('module-edit.delete.title'),
          modalDescription: t('module-edit.delete.description'),
          ctaLabel: t('module-edit.delete.cta-delete'),
          onDelete: () =>
            courseId && moduleId && deleteCourseModuleMutation({ courseId, moduleId }),
        });
        break;
    }
  };

  const actions: ModuleList.Actions = {
    module: {
      create: createModuleMutation,
      duplicate: (moduleId: string) => duplicateCourseModuleMutation({ courseId, moduleId }),
      delete: (moduleId: string) => handleDeleteAction({ moduleId }, 'module'),
      update: updateModuleMutation,
      updateOrder: updateCourseModuleOrderMutation,
      startBehaviourGeneration: async () =>
        (await startBehaviourGenerationMutation.mutateAsync({ input: { courseId } }))
          .behaviourGeneration ?? false,
      fetchSuggestedBehaviours: async (companyId: string, projectId: string) => {
        const result = (await fetchSuggestedBehaviours({ companyId, projectId, nrPerPage: 5 }))
          .suggestedTransferObjectives;
        return {
          status: result.status,
          suggestions: result.transferObjectives ?? [],
        };
      },
      startBehaviourContentGeneration: async (courseId, behaviours) => {
        const result = await startBehaviourContentGenerationMutation.mutateAsync({
          input: {
            courseId,
            behaviours: behaviours.map((b) => ({ name: b.title, description: b.description })),
          },
        });

        // reset course outline queries
        if (result.behaviourContentGeneration) {
          queryClient.invalidateQueries(['course', courseId]);
        }

        return result.behaviourContentGeneration ?? false;
      },
    },
    learningActivity: {
      create: handleCreateLearningActivityAction,
      update: handleUpdateLearningActivityMutation,
      duplicate: handleDuplicateLearningActivityMutation,
      delete: (moduleId: string, learningActivityId: string) =>
        handleDeleteAction({ moduleId, learningActivityId }, 'learning-activity'),
      move: handleMoveLearningActivityMutation,
    },
    modal: {
      openGEARModal: () => setIsGEARModalOpen(true),
    },
  };

  return (
    <>
      <Modal
        isOpen={!!deleteModalConfig}
        title={deleteModalConfig?.modalTitle ?? ''}
        onClose={() => setDeleteModalConfig(undefined)}
        buttons={{
          hasShadow: false,
          buttons: [
            <PrimaryButton
              key={1}
              theme="red"
              label={deleteModalConfig?.ctaLabel ?? ''}
              onClick={() => {
                setDeleteModalConfig(undefined);
                deleteModalConfig?.onDelete();
              }}
            />,
            <LinkButton
              key={2}
              buttonStyle="fit"
              onClick={() => setDeleteModalConfig(undefined)}
              label={tGeneral('cancel')}
            />,
          ],
        }}
        className="bg-surface-01 h-full md:h-auto md:w-[510px]"
      >
        <p className="text-text-01 type-body">{deleteModalConfig?.modalDescription}</p>
      </Modal>
      <GEARTemplateModal
        isOpen={isGEARModalOpen}
        onClose={() => setIsGEARModalOpen(false)}
      />
      <UIShell
        headerTheme={isEditing ? 'edit' : 'view'}
        scrollOnDesktop={true}
        autoScrollTop={false}
        leftButton={
          isEditing
            ? {
                label: tGeneral('back'),
                action: () => setIsEditing(false),
              }
            : undefined
        }
        navHeaderTitle={isEditing && t('edit-modules')}
        useContentContainer={false}
      >
        {course?.outline ? (
          <ModuleList.ModuleList
            currentCompanyId={currentCompany?.id ?? undefined}
            currentUserId={currentUser?.id ?? undefined}
            userRole="designer"
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            initialModules={course.outline.modules ?? []}
            forcedModuleId={forceModuleId}
            loadingMoreModules={{
              loading: (course.aiGenerated && !course.aiGenerationDone) ?? false,
              amount: course.transferObjectives?.length ?? 0,
            }}
            totalLearners={(course.courseStats as CourseStats)?.numberOfStudents ?? 0}
            courseId={courseId}
            aiProject={course.aiProject ?? undefined}
            hint={{
              icon: <Comment2Info className="text-primary-02 h-5 w-5" />,
              title: t('gear-hint.title'),
              description: t('gear-hint.description'),
              onClick: () => setIsGEARModalOpen(true),
            }}
            i18n={{
              learningActivityEditDrawer: {
                activityName: {
                  label: tLaEdit('activity-name'),
                  placeholder: tLaEdit('activity-name-placeholder'),
                },
                description: {
                  label: tGeneral('description'),
                  placeholder: tLaEdit('description-placeholder'),
                },
                openDate: {
                  label: tLaEdit('open-date'),
                },
                duration: {
                  label: tGeneral('duration'),
                  durationPicker: {
                    addLabel: tLaEdit('add-other-time'),
                    addCta: tLaEdit('add-time'),
                    hour: tGeneral('hours'),
                    min: tGeneral('mins'),
                    h: tGeneral('time-hour-short'),
                    validation: {
                      number: tValidation('number'),
                      max: (n) => tValidation('max', { number: n }),
                      min: (n) => tValidation('min', { number: n }),
                      sum: tLaEdit('validation.sum-hours-min'),
                    },
                    duration: {
                      timeHourShort: tGeneral('time-hour-short'),
                      timeMinuteShort: tGeneral('time-minute-short'),
                      hour: (count) => tGeneral('hour', { count }),
                      min: (count) => tGeneral('min', { count }),
                    },
                  },
                },
                prerequisites: {
                  label: tGeneral('prerequisites'),
                  addNew: tGeneral('add-new'),
                  placeholder: tLaEdit('type-activity-name'),
                },
                buttons: {
                  save: tGeneral('save'),
                  cancel: tGeneral('cancel'),
                  delete: tLaEdit('delete'),
                },
                errors: {
                  required: tValidation('required'),
                },
                steps: {
                  title: tLaEdit('steps.title'),
                  addStep: tLaEdit('steps.add-step'),
                  floatingStepOptions: {
                    block: tLaEdit('steps.block.label'),
                    survey: tLaEdit('steps.survey.label'),
                    assessment: tLaEdit('steps.assessment.label'),
                    action: tLaEdit('steps.wba.label'),
                    url: tLaEdit('steps.url.label'),
                    scenario: tLaEdit('steps.scenario.label'),
                  },
                },
                menu: {
                  duplicate: tGeneral('duplicate'),
                  delete: tGeneral('delete'),
                },
                types: {
                  [LearningActivityType.Apply]: tLaEdit('types.apply'),
                  [LearningActivityType.Experiment]: tLaEdit('types.experiment'),
                  [LearningActivityType.Guide]: tLaEdit('types.guide'),
                  [LearningActivityType.Retain]: tLaEdit('types.retain'),
                  [LearningActivityType.Unknown]: tLaEdit('types.miscellaneous'),
                },
              },
              learningActivityList: {
                learningActivityTypes: {
                  guide: {
                    label: t('learning-activity-types.guide.label'),
                    description: t('learning-activity-types.guide.description'),
                  },
                  experiment: {
                    label: t('learning-activity-types.experiment.label'),
                    description: t('learning-activity-types.experiment.description'),
                  },
                  apply: {
                    label: t('learning-activity-types.apply.label'),
                    description: t('learning-activity-types.apply.description'),
                  },
                  retain: {
                    label: t('learning-activity-types.retain.label'),
                    description: t('learning-activity-types.retain.description'),
                  },
                  miscellaneous: {
                    label: t('learning-activity-types.miscellaneous.label'),
                    description: t('learning-activity-types.miscellaneous.description'),
                  },
                },
                emptyStateDesignerMessage: t('empty-learning-activities'),
                activityAvailableOnTooltip: (date: string) =>
                  t('activity-available-on', { date: date }),
                activityAvailableWhenTooltip: (count: number, prerequisites: string) =>
                  t('activity-available-when', { count: count, prerequisites: prerequisites }),
                lockedActivityText: t('locked-activity'),
                activityCommentCount: (count: number) =>
                  t('activity-comment-count', { count: count }),
                mins: (count: number) => tGeneral('min', { count }),
                aiBadgeLabel: tGeneral('ai-badge-label'),
                descriptionSection: {
                  duration: {
                    timeHourShort: tGeneral('time-hour-short'),
                    timeMinuteShort: tGeneral('time-minute-short'),
                    hour: (count) => tGeneral('hour', { count }),
                    min: (count) => tGeneral('min', { count }),
                  },
                },
                exampleDescription: tLaEdit('example-description'),
                addNewLearningActivity: t('add-learning-activity'),
                duplicate: tGeneral('duplicate'),
              },
            }}
            actions={actions}
          />
        ) : (
          <LogoLoader />
        )}
      </UIShell>
    </>
  );
};
