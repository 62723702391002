import {
  CreateJourneyGenerationProjectMutationVariables,
  CreateTransferObjectivesMutationVariables,
  graphql,
  MutationCreateCourseArgs,
  SuggestedTransferObjectivesQueryVariables,
} from '@stellar-lms-frontend/lms-graphql';
import { GraphQLClient } from 'graphql-request';
import { graphQLClient } from '../../../lib/graphql';

export const createCourse = (client: GraphQLClient, variables: MutationCreateCourseArgs) => {
  return client.request(
    graphql(`
      mutation CreateCourseMutation($companyId: ID!, $course: CourseInput!) {
        createCourse(companyId: $companyId, course: $course) {
          id
          title
        }
      }
    `),
    variables
  );
};

export const createJourneyGenerationProject = (
  variables: CreateJourneyGenerationProjectMutationVariables
) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation CreateJourneyGenerationProject($input: JourneyGenerationProjectInput!) {
        createJourneyGenerationProject(input: $input) {
          id
          realtimeChannel {
            id
          }
        }
      }
    `),
    variables
  );
};

export const getSuggestedTransferObjectives = (
  variables: SuggestedTransferObjectivesQueryVariables
) => {
  return graphQLClient('designer').request(
    graphql(`
      query SuggestedTransferObjectives($companyId: ID!, $projectId: ID!, $nrPerPage: Int!, $cursor: String) {
        suggestedTransferObjectives(companyId: $companyId, projectId: $projectId, nrPerPage: $nrPerPage, cursor: $cursor) {
          status
          pageInfo {
            cursor
            hasNextPage
          }
          transferObjectives {
            description
            id
            title
          }
        }
      }
    `),
    variables
  );
};

export const createTransferObjectives = (variables: CreateTransferObjectivesMutationVariables) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation CreateTransferObjectives(
        $companyId: ID!
        $input: [TransferObjectiveInput!]!
        $courseId: ID!
      ) {
        createTransferObjectives(companyId: $companyId, input: $input, courseId: $courseId) {
          id
        }
      }
    `),
    variables
  );
};
