import React from 'react';
import { DocumentData } from './document-plugin';
import { EmptyStateCard } from '../../../card/empty-state-card/empty-state-card';
import { renderFileIcon } from '../../../icons/file-icons.util';
import { faFileArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface DocumentViewComponentProps {
  data: DocumentData;
}

export const DocumentViewComponent: React.FC<DocumentViewComponentProps> = ({ data }) => {
  return (
    <div className="py-3">
      <a
        href={data.file.url}
        target="_blank"
        rel="noreferrer"
        download
        draggable={false}
      >
        <EmptyStateCard>
          <div className="flex flex-row items-center justify-center space-x-4">
            {renderFileIcon(data.mimeType)}
            <span className="text-text-01 grow">{data.filename}</span>
            <FontAwesomeIcon
              icon={faFileArrowDown}
              className="h-6 w-6"
            />
          </div>
        </EmptyStateCard>
      </a>
    </div>
  );
};
