import { GraphQLClient } from 'graphql-request';
import {
  CompaniesQueryVariables,
  UpdateSubscriptionMutationVariables,
  CancelCurrentSubscriptionMutationVariables,
  graphql,
  DeleteCompanyMutationVariables,
} from '@stellar-lms-frontend/lms-graphql';

export const getCompanies = (graphQLClient: GraphQLClient, variables: CompaniesQueryVariables) => {
  return graphQLClient
    .request({
      document: graphql(`
        query AdministrationCompanies($max: Int!, $query: String) {
          companies(max: $max, query: $query) {
            id
            name
            createdAt
            hasLearnerLicense
            hidePerformanceManagement
            journeyCount
            memberCount
            subscription {
              id
              active
              type
              startDate
              endDate
            }
          }
        }
      `),
      variables,
    })
    .then((res) => res.companies);
};

export const updateSubscription = (
  graphQLClient: GraphQLClient,
  variables: UpdateSubscriptionMutationVariables
) => {
  return graphQLClient.request({
    document: graphql(`
      mutation UpdateSubscription($input: UpdateSubscriptionInput!, $companyId: ID!) {
        updateSubscription(input: $input, companyId: $companyId) {
          id
        }
      }
    `),
    variables,
  });
};

export const cancelCurrentSubscription = (
  graphQLClient: GraphQLClient,
  variables: CancelCurrentSubscriptionMutationVariables
) => {
  return graphQLClient.request({
    document: graphql(`
      mutation CancelCurrentSubscription($companyId: ID!) {
        cancelCurrentSubscription(companyId: $companyId)
      }
    `),
    variables,
  });
};

export const deleteCompany = (
  GraphQLClient: GraphQLClient,
  variables: DeleteCompanyMutationVariables
) => {
  return GraphQLClient.request({
    document: graphql(`
      mutation DeleteCompany($deleteCompanyId: ID!) {
        deleteCompany(id: $deleteCompanyId)
      }
    `),
    variables,
  });
};
