import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

export const setupSentry = (environment: 'staging' | 'production' | 'local', dsn: string) => {
  // No sentry for local development
  if (environment === 'local') {
    return;
  }

  const tracingSampleRate = environment === 'production' ? 0.1 : 1.0;

  Sentry.init({
    dsn: dsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    environment: environment,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: tracingSampleRate,
  });
};

export async function unimplementedActionError() {
  Sentry.captureException('Unimplemented action called while it should not have been called');
  return Promise.reject();
}
