import {
  CourseDetailsSection,
  LearnersList,
  DashboardLayout,
} from '@stellar-lms-frontend/lms-components';
import { LogoLoader } from '@stellar-lms-frontend/ui-components';
import { useQuery } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { MENTOR_COURSE_DASHBOARD } from '../../constants/query';
import { MENTOR_LEARNERS_ROUTE } from '../../constants/routes';
import { WBASection } from '../mentors/components/wba-section';
import { graphQLClient } from '../../lib/graphql';
import { MentorInsightsSection } from '../mentors/components/mentor-insights-section';
import { getMentorCourseDashboard } from './mentor-course-dashboard.api';
import {
  CourseStats,
  mapLearnersToUsersList,
  mapUsersToUsersList,
} from '@stellar-lms-frontend/lms-graphql';
import { useMemo } from 'react';
import { UIShell } from '../shell/ui-shell';

const MAX_MENTORS = 5;
const MAX_LEARNERS = 5;
const MAX_DESIGNERS = 5;

export const MentorDashboardPage = () => {
  const { key } = useLocation();
  const { t } = useTranslation('translation', { keyPrefix: 'mentor-course-dashboard' });
  const { t: tMenteesOverview } = useTranslation('translation', { keyPrefix: 'mentees-overview' });

  const { courseId = '' } = useParams();

  const courseDashboard = useQuery(
    [MENTOR_COURSE_DASHBOARD, courseId],
    () => (courseId ? getMentorCourseDashboard(graphQLClient('mentor'), { id: courseId }) : null),
    { enabled: !!courseId }
  );

  const courseDesigners = useMemo(
    () => mapUsersToUsersList(courseDashboard.data?.designers, MAX_DESIGNERS),
    [courseDashboard.data?.designers]
  );

  const courseMentors = useMemo(
    () => mapUsersToUsersList(courseDashboard.data?.mentors, MAX_MENTORS),
    [courseDashboard.data?.mentors]
  );

  const courseLearners = useMemo(
    () => mapLearnersToUsersList(courseDashboard.data?.learners, MAX_LEARNERS),
    [courseDashboard.data?.learners]
  );

  return (
    <UIShell key={key}>
      {courseDashboard.data ? (
        <DashboardLayout
          left={
            <CourseDetailsSection
              course={courseDashboard.data}
              i18n={{
                title: t('course-details.title'),
                readLess: t('course-details.read-less'),
                readMore: t('course-details.read-more'),
                skills: {
                  label: t('course-details.skills.label'),
                  empty: t('course-details.skills.empty'),
                },
                objectives: {
                  label: t('course-details.business-objectives.label'),
                  empty: t('course-details.business-objectives.empty'),
                },
                transferObjectives: {
                  label: t('course-details.transfer-objectives.label'),
                  empty: t('course-details.transfer-objectives.empty'),
                },
                languages: {
                  label: t('course-details.language'),
                },
              }}
            />
          }
          center={
            <>
              {courseDashboard.data?.courseStats && (
                <MentorInsightsSection stats={courseDashboard.data.courseStats as CourseStats} />
              )}
              <WBASection />
            </>
          }
          right={
            <>
              {courseDashboard.data.designers && (
                <LearnersList
                  title={t('course-details.designer.label')}
                  learners={courseDesigners}
                  dataCy="designer-list"
                  emptyMessage={t('course-details.designer.empty')}
                  viewAll={{
                    text: t('course-details.designer.all-designers'),
                    target: MENTOR_LEARNERS_ROUTE(courseId),
                  }}
                />
              )}
              {courseDashboard.data.mentors && (
                <LearnersList
                  title={t('mentors.title')}
                  learners={courseMentors}
                  emptyMessage={t('mentors.empty')}
                  dataCy="mentor-list"
                  viewAll={{
                    text: t('mentors.all-mentors'),
                    target: MENTOR_LEARNERS_ROUTE(courseId),
                  }}
                />
              )}
              {courseDashboard.data.learners && (
                <LearnersList
                  title={tMenteesOverview('learners')}
                  learners={courseLearners}
                  dataCy="learners-list"
                  emptyMessage={tMenteesOverview('learners-empty')}
                  viewAll={{
                    text: tMenteesOverview('all-learners'),
                    target: MENTOR_LEARNERS_ROUTE(courseId),
                  }}
                />
              )}
            </>
          }
        />
      ) : (
        <LogoLoader />
      )}
    </UIShell>
  );
};
