import { BaseCard } from '../base-card/base-card';

type BarConfig = {
  height: string;
  width: string;
};

export type SkeletonCardProps = {
  bgColor?: string;
  barConfigs: BarConfig[];
  className?: string;
};

export const SkeletonCard: React.FC<SkeletonCardProps> = ({
  bgColor = 'bg-surface-02',
  barConfigs,
  className = '',
}) => {
  return (
    <BaseCard
      className={`animate-fadeInOutDissolve px-6 py-8 ${bgColor} ${className}`}
      childrenClassName="flex flex-col gap-6"
    >
      {barConfigs.map(({ width, height }) => (
        <div
          style={{ width, height }}
          className="bg-text-03 rounded-full opacity-60"
        />
      ))}
    </BaseCard>
  );
};
