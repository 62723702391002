import { forwardRef, useImperativeHandle, useRef, useEffect, useState, useMemo } from 'react';
import EditorJs, { EditorConfig, LogLevels, OutputData } from '@editorjs/editorjs';
import { H1 } from './plugins/header/h1-plugin';
import { H2 } from './plugins/header/h2-plugin';
import { H3 } from './plugins/header/h3-plugin';
import { H4 } from './plugins/header/h4-plugin';
import { Paragraph } from './plugins/paragraph/paragraph-plugin';
import { Warning } from './plugins/warning/warning-plugin';
import { Example } from './plugins/example/example-plugin';
import { Divider } from './plugins/divider/divider-plugin';
import { Article } from './plugins/article/article-plugin';
import { Document } from './plugins/document/document-plugin';
import { DiscussionPrompt } from './plugins/discussion-prompt/discussion-prompt-plugin';
import './image-tool.css';

// For those plugins we don't have TS
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import List from '@editorjs/list';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Underline from '@editorjs/underline';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Table from '@editorjs/table';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CodeTool from '@editorjs/code';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Checklist from '@editorjs/checklist';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ImageTool from '@editorjs/image';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Embed from '@editorjs/embed';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DragDrop from 'editorjs-drag-drop';
import { KeyTakeaways } from './plugins/key-takeaways/key-takeaways-plugin';
import { PracticalTips } from './plugins/practical-tips/practical-tips-plugin';
import LinkInlineTool from './inline-tools/link/link-inline-tool';
import { Uploader, VoidFunc } from '@stellar-lms-frontend/common-utils';
import { useTranslation } from 'react-i18next';
import { EditorJsArticleBlock } from '@stellar-lms-frontend/lms-graphql';

export enum BlockEditorTypesEnum {
  PARAGRAPH = 'paragraph',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  TABLE = 'table',
  LIST = 'list',
  CODE = 'code',
  CHECKLIST = 'checklist',
  IMAGE = 'image',
  EMBED = 'embed',
  PRACTICAL_TIPS = 'practical_tips',
  KEY_TAKEAWAYS = 'key_takeaways',
  WARNING = 'warning',
  EXAMPLE = 'example',
  DIVIDER = 'divider',
  ARTICLE = 'article',
  DISCUSSION_PROMPT = 'discussion_prompt',
  DOCUMENT = 'document',
}

export type BlockEditorData = {
  time: number;
  version: string;
  blocks: OutputData;
};

export type BlockEditorUploadResponse = {
  url: string;
  fileId: string;
};

export type BlockEditorProps = {
  id: string;
  className?: string;
  data?: OutputData;
  documentsEnabled?: boolean;
  onSupportClick?: VoidFunc;
} & (
  | {
      isReadOnly?: false;
      placeholder: string;
      uploader: Uploader;
      articleUrlAnalyserFunc: (url: string) => Promise<EditorJsArticleBlock | undefined>;
      onDiscussClick?: never;
    }
  | {
      isReadOnly: true;
      placeholder?: never;
      uploader?: never;
      articleUrlAnalyserFunc?: never;
      onDiscussClick: VoidFunc;
    }
);

export interface BlockEditorRef {
  getEditor: () => EditorJs | null;
}

export const BlockEditor = forwardRef<BlockEditorRef, BlockEditorProps>(
  (
    {
      className = '',
      id,
      placeholder,
      isReadOnly,
      data,
      uploader,
      articleUrlAnalyserFunc,
      onDiscussClick,
      onSupportClick,
      documentsEnabled = false,
    },
    ref
  ) => {
    const { t } = useTranslation('translation', { keyPrefix: 'block-step-view.block-editor' });
    const editorRef = useRef<EditorJs | null>(null);
    const [isLoadingEditor, setIsLoadingEditor] = useState(true);

    useImperativeHandle(ref, () => ({
      getEditor: () => editorRef.current,
    }));

    const newEditor = useMemo(
      () => {
        const editorConfig: EditorConfig = {
          holder: id,
          data,
          logLevel: 'ERROR' as LogLevels,
          placeholder: placeholder,
          readOnly: isReadOnly,
          onReady: () => {
            setIsLoadingEditor(false);
            new DragDrop(newEditor);
            editorRef.current = newEditor;
          },
          tools: {
            [BlockEditorTypesEnum.ARTICLE]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: Article,
              inlineToolbar: false,
              config: {
                urlAnalyserFunc: articleUrlAnalyserFunc,
              },
            },
            [BlockEditorTypesEnum.CHECKLIST]: Checklist,
            [BlockEditorTypesEnum.CODE]: CodeTool,
            [BlockEditorTypesEnum.DISCUSSION_PROMPT]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: DiscussionPrompt,
              config: {
                onDiscussClick: onDiscussClick,
              },
            },
            [BlockEditorTypesEnum.DIVIDER]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: Divider,
              inlineToolbar: true,
            },
            [BlockEditorTypesEnum.DOCUMENT]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: Document,
              config: {
                documentsEnabled: documentsEnabled,
                onSupportClick: onSupportClick,
                // Custom Uploader
                uploader: {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  uploadByFile(file: File, callback?: (progress: number) => void) {
                    return uploader?.uploadByFile(file, callback);
                  },
                },
              },
            },
            [BlockEditorTypesEnum.EXAMPLE]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: Example,
              inlineToolbar: true,
            },
            [BlockEditorTypesEnum.IMAGE]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: ImageTool,

              config: {
                captionPlaceholder: t('image-tool.select'),
                buttonContent: t('image-tool.caption-placeholder'),
                // Custom Uploader
                uploader: {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  uploadByFile(file: File) {
                    return uploader?.uploadByFile(file).then((response) => {
                      return {
                        success: 1,
                        file: {
                          url: response.url,
                          id: response.fileId,
                        },
                      };
                    });
                  },
                  uploadByUrl(url: string) {
                    return uploader?.uploadByUrl(url).then((response) => {
                      return {
                        success: 1,
                        file: {
                          url: response.url,
                          id: response.fileId,
                        },
                      };
                    });
                  },
                },
              },
            },
            // Key Takeaways
            [BlockEditorTypesEnum.KEY_TAKEAWAYS]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: KeyTakeaways,
              inlineToolbar: true,
            },
            [BlockEditorTypesEnum.LIST]: {
              class: List,
              inlineToolbar: true,
            },
            // Practical Tips
            [BlockEditorTypesEnum.PRACTICAL_TIPS]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: PracticalTips,
              inlineToolbar: true,
            },
            [BlockEditorTypesEnum.TABLE]: Table,
            [BlockEditorTypesEnum.WARNING]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: Warning,
              inlineToolbar: true,
            },

            // Headers and items which aren't in the list
            [BlockEditorTypesEnum.H1]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: H1,
              inlineToolbar: true,
            },
            [BlockEditorTypesEnum.H2]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: H2,
              inlineToolbar: true,
            },
            [BlockEditorTypesEnum.H3]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: H3,
              inlineToolbar: true,
            },
            [BlockEditorTypesEnum.H4]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: H4,
              inlineToolbar: true,
            },
            [BlockEditorTypesEnum.PARAGRAPH]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: Paragraph,
              inlineToolbar: true,
            },

            // embed: Embed,
            [BlockEditorTypesEnum.EMBED]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              class: Embed,
              inlineToolbar: true,
              config: {
                services: {
                  youtube: true,
                  miro: true,
                  vimeo: true,
                  imgur: true,
                  gfycat: true,
                  genially: {
                    regex: /^https:\/\/view.(?:genial.ly|genially.com)\/([a-z0-9]*)(\/.*)?\/?$/,
                    embedUrl: 'https://view.genially.com/<%= remote_id %>',
                    html: "<iframe frameborder='0' style='width: 100%; aspect-ratio: 1920/1080' allowfullscreen ></iframe>",
                    height: 320,
                    width: 600,
                    id: (groups: string[]) => groups[0],
                  },
                  synthesia: {
                    regex: /^https:\/\/share\.synthesia\.io\/(?:embeds\/videos\/)?([a-z0-9-]+)$/,
                    embedUrl: 'https://share.synthesia.io/embeds/videos/<%= remote_id %>',
                    html: "<iframe frameborder='0' style='width: 100%; aspect-ratio: 1920/1080' loading='lazy' allow='encrypted-media; fullscreen;' ></iframe>",
                    height: 320,
                    width: 600,
                    id: (groups: string[]) => groups[0],
                  },
                  nepazing: {
                    regex: /https:\/\/nepazing.com\/wordsearch\/embed\/([a-zA-Z0-9]*)/,
                    embedUrl: 'https://nepazing.com/wordsearch/embed/<%= remote_id %>',
                    html: "<iframe frameborder='0' style='height: 1000px; width: 100%;' allowfullscreen ></iframe>",
                    height: 1000,
                    width: 600,
                    id: (groups: string[]) => groups[0],
                  },
                  outgrow: {
                    regex: /^https:\/\/(.*).outgrow.us\/([a-z0-9]*)\??(.*)?$/,
                    embedUrl: '<%= remote_id %>',
                    html: "<iframe frameborder='0' style='height: 1000px; width: 100%;' allowfullscreen ></iframe>",
                    height: 1000,
                    width: 600,
                    id: (groups: string[]) => {
                      const url: string = 'https://' + groups[0] + '.outgrow.us/' + groups[1];
                      return url;
                    },
                  },
                  hubspot: {
                    regex: /^(https:\/\/.*.hubspot.com\/.*)$/,
                    embedUrl: '<%= remote_id %>',
                    html: "<iframe frameborder='0' style='height: 700px; width: 100%;' allowfullscreen ></iframe>",
                    height: 700,
                    width: 600,
                    id: (groups: string[]) => groups[0],
                  },
                },
              },
            },
            // Inline tools
            underline: Underline,
            link: LinkInlineTool,
          },
          i18n: {
            messages: {
              toolNames: {
                [BlockEditorTypesEnum.PARAGRAPH]: t('tool-names.body-text'),
                [BlockEditorTypesEnum.H1]: t('tool-names.h1'),
                [BlockEditorTypesEnum.H2]: t('tool-names.h2'),
                [BlockEditorTypesEnum.H3]: t('tool-names.h3'),
                [BlockEditorTypesEnum.H4]: t('tool-names.h4'),
                // Do not use the enum for list, we do not have control over the plugin and they named it differently
                Code: t('tool-names.code'),
                // Do not use the enum for list, we do not have control over the plugin and they named it differently
                List: t('tool-names.list'),
                // Do not use the enum for table, we do not have control over the plugin and they named it differently
                Table: t('tool-names.table'),
                Bold: t('tool-names.bold'),
                Italic: t('tool-names.italic'),
                Link: t('tool-names.link'),
                Underline: t('tool-names.underline'),
                // Do not use the enum for image, we do not have control over the plugin and they named it differently
                Image: t('tool-names.image'),
                [BlockEditorTypesEnum.PRACTICAL_TIPS]: t('tool-names.practical-tips'),
                [BlockEditorTypesEnum.KEY_TAKEAWAYS]: t('tool-names.key-takeaways'),
                [BlockEditorTypesEnum.WARNING]: t('tool-names.warning'),
                [BlockEditorTypesEnum.EXAMPLE]: t('tool-names.example'),
                [BlockEditorTypesEnum.DIVIDER]: t('tool-names.divider'),
                // Do not use the enum for checklist, we do not have control over the plugin and they named it differently
                Checklist: t('tool-names.checklist'),
                [BlockEditorTypesEnum.ARTICLE]: t('tool-names.article'),
                [BlockEditorTypesEnum.DISCUSSION_PROMPT]: t('tool-names.discussion-prompt'),
                [BlockEditorTypesEnum.DOCUMENT]: t('tool-names.document'),
              },
              blockTunes: {
                delete: {
                  Delete: t('block-tunes.delete.delete'),
                  'Click to delete': t('block-tunes.delete.click-to-delete'),
                },
                moveUp: {
                  'Move up': t('block-tunes.move-up.move-up'),
                },
                moveDown: {
                  'Move down': t('block-tunes.move-down.move-down'),
                },
              },
              ui: {
                blockTunes: {
                  toggler: {
                    'Click to tune': t('ui.block-tunes.toggler.click-to-tune'),
                    'or drag to move': t('ui.block-tunes.toggler.or-drag-to-move'),
                  },
                },
                inlineToolbar: {
                  converter: {
                    'Convert to': t('ui.inline-toolbar.converter.convert-to'),
                  },
                },
                toolbar: {
                  toolbox: {
                    Add: t('ui.toolbar.toolbox.add'),
                  },
                },
                popover: {
                  Filter: t('ui.popover.filter'),
                  'Nothing found': t('ui.popover.nothing-found'),
                },
              },
              tools: {
                [BlockEditorTypesEnum.WARNING]: {
                  'title-placeholder': t('tools.warning.warning-label'),
                },
                [BlockEditorTypesEnum.ARTICLE]: {
                  'error-message': t('tools.article.error-message'),
                  'add-article-title': t('tools.article.add-article-title'),
                  'input-placeholder': t('tools.article.input-placeholder'),
                  'add-article-button': t('tools.article.add-article-button'),
                  'add-article-hint-description': t('tools.article.add-article-hint-description'),
                  'edit-article-menu-button': t('tools.article.edit-article-menu-button'),
                },
                [BlockEditorTypesEnum.DISCUSSION_PROMPT]: {
                  'title-placeholder': t('tools.discussion-prompt.title-placeholder'),
                  'description-placeholder': t('tools.discussion-prompt.description-placeholder'),
                  'show-discuss-panel-button': t(
                    'tools.discussion-prompt.show-discuss-panel-button'
                  ),
                  'no-discuss-panel-title': t('tools.discussion-prompt.no-discuss-panel-title'),
                  'no-discuss-panel-description': t(
                    'tools.discussion-prompt.no-discuss-panel-description'
                  ),
                },
                [BlockEditorTypesEnum.EXAMPLE]: {
                  'title-placeholder': t('tools.example.title-placeholder'),
                  'description-placeholder': t('tools.example.description-placeholder'),
                },
                [BlockEditorTypesEnum.KEY_TAKEAWAYS]: {
                  'title-placeholder': t('tools.key-takeaways.title-placeholder'),
                },
                [BlockEditorTypesEnum.PRACTICAL_TIPS]: {
                  'title-placeholder': t('tools.practical-tips.title-placeholder'),
                },
                [BlockEditorTypesEnum.DOCUMENT]: {
                  'invalid-file-format': t('tools.document.invalid-format'),
                  'file-too-large': t('tools.document.max-file-size'),
                  'not-supported-label': t('tools.document.not-supported.label'),
                  'not-supported-cta': t('tools.document.not-supported.cta'),
                },
              },
            },
          },
        };
        return new EditorJs(editorConfig);
      },
      // Should run only on first render
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    useEffect(() => {
      data && data?.blocks.length > 0 && !isLoadingEditor && newEditor?.render?.(data);
    }, [data, newEditor, isLoadingEditor]);

    useEffect(() => {
      return () => {
        editorRef.current?.destroy?.();
      };
    }, []);

    return (
      <div
        onKeyDown={(e) => e.stopPropagation()}
        onKeyUp={(e) => e.stopPropagation()}
        className={`${className} font-lexend text-text-01 w-full`}
        id={id}
      />
    );
  }
);
