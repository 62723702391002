import { LogoLoader, Wizard, WizardStep } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  StepChooseTransferObjectives,
  TransferObjective,
} from './components/step-choose-transfer-objectives';
import { StepChooseSkill } from './components/step-choose-skill';
import { StepDefineAudience } from './components/step-define-audience';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createJourneyGenerationProject } from './create-journey.api';
import {
  InputLanguage,
  Language,
  mapToInputLanguage,
  useCompanyStore,
} from '@stellar-lms-frontend/lms-graphql';
import { USER } from '../constants/query-constants';
import { StepAddDocuments } from './components/step-add-documents';
import { graphQLClient } from '../../../lib/graphql';
import { getLoggedInUser } from '@stellar-lms-frontend/lms-api';
import { findLanguageByValue } from '../../../constants/languages';

export type CreateJourneyProjectData = {
  skill: string;
  targetAudience: string;
  industry: string;
  language: Language;
  companyId: string;
  projectId: string;
  realtimeChannel: string;
  allTransferObjectives: TransferObjective[];
};

const formsDataDefault = {
  skill: '',
  targetAudience: '',
  industry: '',
  companyId: '',
  projectId: '',
  realtimeChannel: '',
  allTransferObjectives: [],
  createOption: undefined,
  language: Language.En,
};

export const CreateJourneyPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'create-journey' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const [formsData, setFormsData] = useState<CreateJourneyProjectData>(formsDataDefault);

  const { data: user } = useQuery([USER], () => getLoggedInUser(graphQLClient()), {
    staleTime: 60000,
  });

  const companyId = useCompanyStore((state) => state.companyId);

  useEffect(() => {
    setFormsData((f) => {
      return {
        ...f,
        language: findLanguageByValue(user?.language),
        companyId: companyId ?? '',
      };
    });
  }, [user?.language, user?.companyReference, companyId]);

  const { mutate: createJourneyGenerationProjectMutation } = useMutation(
    createJourneyGenerationProject,
    {
      onSuccess: (data) => {
        setFormsData((prev) => ({
          ...formsDataDefault,
          skill: prev.skill,
          targetAudience: prev.targetAudience,
          industry: prev.industry,
          language: prev.language,
          companyId: prev.companyId,
          projectId: data.createJourneyGenerationProject.id,
          realtimeChannel: data.createJourneyGenerationProject.realtimeChannel.id,
          allTransferObjectives: [],
        }));
      },
    }
  );

  useEffect(() => {
    if (
      formsData.skill &&
      formsData.industry &&
      formsData.targetAudience &&
      formsData.language &&
      user
    ) {
      createJourneyGenerationProjectMutation({
        input: {
          skill: formsData.skill,
          audience: formsData.targetAudience,
          industry: formsData.industry,
          companyId: companyId ?? user.companyReference.id,
          language: mapToInputLanguage(formsData.language) ?? InputLanguage.En,
          userId: user.id,
        },
      });
    }
  }, [
    formsData.skill,
    formsData.industry,
    formsData.language,
    formsData.targetAudience,
    companyId,
    user,
    createJourneyGenerationProjectMutation,
  ]);

  const onSubmitSkill = useCallback(
    (skill: string) =>
      setFormsData((prev) => ({
        ...prev,
        ...(prev.skill !== skill ? { allTransferObjectives: [], projectId: '' } : {}),
        skill,
      })),
    []
  );

  const onSubmitTargetAudience = useCallback(
    (data: { targetAudience: string; industry: string; language: Language }) => {
      setFormsData((prev) => ({
        ...prev,
        ...(prev.targetAudience !== data.targetAudience || prev.industry !== data.industry
          ? { allTransferObjectives: [], projectId: '' }
          : {}),
        skill: prev.skill,
        targetAudience: data.targetAudience,
        industry: data.industry,
        language: data.language,
      }));
    },
    []
  );

  const onSubmitTransferObjectives = useCallback(
    (allTransferObjectives: TransferObjective[]) =>
      setFormsData((prev) => ({ ...prev, allTransferObjectives, createOption: undefined })),
    []
  );

  const steps = useMemo<WizardStep[]>(
    () => [
      {
        component: (
          <StepChooseSkill
            onSubmit={onSubmitSkill}
            skill={formsData.skill}
          />
        ),
        isCompleted: !!formsData.skill,
        title: t('steps.choose-skill.title'),
      },
      {
        component: (
          <StepDefineAudience
            onSubmit={onSubmitTargetAudience}
            skill={formsData.skill}
            data={{ ...formsData }}
          />
        ),
        isCompleted: !!formsData.industry && !!formsData.targetAudience,
        title: t('steps.define-audience.title'),
      },
      {
        component: formsData.realtimeChannel ? (
          <StepChooseTransferObjectives
            onSubmit={onSubmitTransferObjectives}
            data={{ ...formsData }}
            companyId={formsData.companyId}
            projectId={formsData.projectId}
            realtimeChannel={formsData.realtimeChannel}
          />
        ) : (
          <LogoLoader />
        ),
        isCompleted: formsData.allTransferObjectives.filter((s) => s.isSelected).length > 0,
        title: t('steps.choose-transfer-objectives.title'),
      },
      {
        component: <StepAddDocuments data={formsData} />,
        isCompleted: false,
        title: t('steps.add-documents.step-title'),
      },
    ],
    [formsData, onSubmitTransferObjectives, onSubmitSkill, onSubmitTargetAudience, t]
  );

  return (
    <Wizard
      steps={steps}
      allowStepClick
      i18n={{
        title: t('headline'),
        buttons: {
          left: tGeneral('home'),
          rightPrimaryDefault: tGeneral('next'),
          rightPrimaryLast: tGeneral('create'),
          rightSecondary: tGeneral('previous'),
        },
      }}
    />
  );
};
