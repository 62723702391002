import React, { useEffect, useState } from 'react';
import { ScenarioLevelView, ScenarioViewLevel } from '../scenario-level-view/scenario-level-view';
import {
  ContentWrapper,
  Divider,
  HeaderImage,
  ScrollContainer,
  SelectOptionAnswer,
  SelectOptionType,
} from '@stellar-lms-frontend/ui-components';
import {
  Assessment,
  Evaluation,
  LearningActivityStep,
  SubmissionResultWithScore,
  SurveyAnswerMap,
  TypeMap,
} from '@stellar-lms-frontend/lms-api';
import { FormProvider, useForm } from 'react-hook-form';
import { getAllQuestions, toSubmissionDTO } from '../../../functions';
import { navigation, unimplementedActionError } from '@stellar-lms-frontend/common-utils';
import { useTranslation } from 'react-i18next';

type ScenarioViewActions = {
  getLastScenarioSubmission: (
    learningActivityId: string,
    scenarioId: string
  ) => Promise<SubmissionResultWithScore | undefined>;
  getScenarioById: (scenarioId: string, learningActivityId: string) => Promise<Assessment>;
  submitPartialAnswers?: (
    learningActivityStepId: string,
    answers: TypeMap<SurveyAnswerMap>,
    scenarioId: string
  ) => Promise<void>;
  evaluate: (
    learningActivityStepId: string,
    scenarioId: string,
    levelId: string,
    answer: TypeMap<SurveyAnswerMap>
  ) => Promise<Evaluation>;
  evaluateWithoutSubmission?: (
    levelIndex: number,
    userInput: SelectOptionType
  ) => Promise<{ answers: SelectOptionAnswer[]; feedback: string }>;
};

type ScenarioMetaInfo = {
  id: string;
  title: string;
};

export type ScenarioViewProps = {
  step: LearningActivityStep;
  actions: ScenarioViewActions;
};

const NAVIGATION_KEY = 'scenario-step';
export const ScenarioView: React.FC<ScenarioViewProps> = ({ step, actions }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'scenario-step-view' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const [levels, setLevels] = useState<ScenarioViewLevel[]>([]);
  const [scenarioMetaInfo, setScenarioMetaInfo] = useState<ScenarioMetaInfo | undefined>(undefined);
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  useEffect(() => {
    const fetchAssessment = async (id: string, activityId: string) => {
      const result = await actions.getLastScenarioSubmission(activityId, id);

      if (result) {
        setLevels(
          result.questions.map((q) => ({
            id: q.id,
            description: q.text,
            options: [],
            answers: q.answers,
            isSelectCollapsed: true,
            feedback: q?.feedback,
          }))
        );
      } else {
        const data = await actions.getScenarioById(id, activityId);
        const questions = getAllQuestions(data.questions);
        reset(questions);

        setScenarioMetaInfo({
          title: data.title,
          id: data.questions[0].assessmentId,
        });

        setLevels(
          data.questions.map((q, index) => ({
            id: q.id,
            description: q.text,
            options: q.answers.map((a) => ({
              id: a.id,
              label: a.text,
              other: false,
            })),
            answers: q.answered ? data.evaluatedQuestions?.[index].answers ?? [] : undefined,
            feedback: data.evaluatedQuestions?.[index]?.feedback ?? '',
            isSelectCollapsed: q.answered,
          }))
        );
      }
    };

    if (step.sourceId) {
      fetchAssessment(step.sourceId, step.id);
    }
  }, [actions, reset, step.id, step.sourceId]);

  const currentLevel =
    levels.findIndex((level) => !level.isSelectCollapsed) !== -1
      ? levels.findIndex((level) => !level.isSelectCollapsed)
      : levels.length - 1;

  useEffect(() => {
    if (levels.length > 0 && !levels[levels.length - 1]?.isSelectCollapsed) {
      navigation.setRightPrimary(NAVIGATION_KEY, {
        label: tGeneral('finish'),
        action: () => null,
        disabled: true,
      });
    }
  }, [tGeneral, levels]);

  useEffect(() => {
    return () => {
      navigation.popRightPrimary(NAVIGATION_KEY);
    };
  }, []);

  useEffect(() => {
    if (levels[levels.length - 1]?.isSelectCollapsed) {
      navigation.popRightPrimary(NAVIGATION_KEY);
    }
  }, [levels]);

  const onSubmitLevel = async (levelId: string) => {
    handleSubmit(async (data) => {
      let evaluationResult: { answers: SelectOptionAnswer[]; feedback: string } | undefined;
      const answersData = toSubmissionDTO(data);

      if (scenarioMetaInfo?.id) {
        actions.submitPartialAnswers?.(step.id, answersData, scenarioMetaInfo.id);
        evaluationResult = await actions.evaluate(step.id, scenarioMetaInfo.id, levelId, {
          [levelId]: answersData[levelId] || {},
        });
      }
      // Designer doesn't have published scenario id, so we handle the evaluation in another way
      else if (actions.evaluateWithoutSubmission) {
        evaluationResult = await actions.evaluateWithoutSubmission(currentLevel, data[levelId]);
      }

      if (evaluationResult) {
        const { answers, feedback } = evaluationResult;

        setLevels((prev) => {
          return prev.map((level, index) =>
            index === currentLevel ? { ...level, answers, feedback } : level
          );
        });
      }

      if (currentLevel === levels.length - 1) {
        handleLevelContinue();
      }
    })();
  };

  const handleLevelContinue = () => {
    setLevels((prev) =>
      prev.map((level, index) =>
        index === currentLevel ? { ...level, isSelectCollapsed: true } : level
      )
    );
  };

  return (
    <ScrollContainer scrollOnDesktop={true}>
      <FormProvider {...methods}>
        <HeaderImage
          isEditing={false}
          value={step.headerImage ?? undefined}
          actions={{
            getUploadInformation: unimplementedActionError,
            uploadFile: unimplementedActionError,
            getFileUrl: unimplementedActionError,
          }}
        />
        <ContentWrapper className="font-lexend mx-auto space-y-4 pt-3 pb-6">
          <p className="type-headline-3 text-text-04">{scenarioMetaInfo?.title}</p>
          <p className="type-body text-text-01">{t('description')}</p>
          <div>
            {levels.slice(0, currentLevel + 1).map((level, index) => (
              <React.Fragment key={level.id}>
                <ScenarioLevelView
                  levelNumber={index + 1}
                  value={level}
                  onSubmit={() => onSubmitLevel(level.id)}
                  onContinue={handleLevelContinue}
                />
                {index < currentLevel && <Divider className="mt-8 mb-4" />}
              </React.Fragment>
            ))}
          </div>
        </ContentWrapper>
      </FormProvider>
    </ScrollContainer>
  );
};
