import { AblyMessageCallback, assertConfiguration } from '@ably-labs/react-hooks';
import { Types } from 'ably';
import { useEffect, useState } from 'react';

export const useAblyChannel = (channelId: string | undefined, callback: AblyMessageCallback) => {
  const [ably, setAbly] = useState<Types.RealtimePromise | undefined>(undefined);

  useEffect(() => {
    let ablyInstance: Types.RealtimePromise | undefined = undefined;

    try {
      ablyInstance = assertConfiguration();
      setAbly(ablyInstance);
    } catch (e) {
      // Ably not set up so we will not use it!
      return;
    }

    onMount(ablyInstance, channelId, callback).catch(console.error);

    return () => {
      if (ablyInstance !== undefined) {
        onUnmount(ablyInstance, channelId).catch(console.error);
      }
    };
  }, [channelId, callback]);

  return [ably ?? (() => null)];
};

const onMount = async (
  ably: Types.RealtimePromise,
  channelId: string | undefined,
  callback: AblyMessageCallback
) => {
  if (channelId) {
    const channel = ably.channels.get(channelId);
    await channel.subscribe.apply(channel, [callback]);
  }
};

const onUnmount = async (ably: Types.RealtimePromise, channelId: string | undefined) => {
  if (channelId) {
    const channel = ably.channels.get(channelId);
    await channel.unsubscribe.apply(channel);

    setTimeout(async () => {
      // React is very mount/unmount happy, so if we just detach the channel
      // it's quite likely it will be reattached again by a subsequent onMount calls.
      // To solve this, we set a timer, and if all the listeners have been removed, we know that the component
      // has been removed for good and we can detach the channel.

      if (channel.listeners.length === 0) {
        await channel.detach();
      }
    }, 2500);
  }
};
